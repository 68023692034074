<template>
 <v-container fluid>
   <v-card flat>
    <v-toolbar
      color="grey darken-4"
      dark
      extended
      flat
    >
      <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
    </v-toolbar>

    <v-card
      class="mx-auto"
      max-width="800"
      style="margin-top: -64px;"
      :loading="loading"
    >
      <v-toolbar flat>
        <v-toolbar-title class="black--text">Respuesta de la transacción</v-toolbar-title>

        <v-spacer></v-spacer>

        <!--<v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>-->

        <v-btn
            class="mr-1 white--text d-print-none"
            color="black"
            :to="`/my-recharges`"
            v-if="store_id"
        >
            <v-icon left>mdi-receipt</v-icon>Ver recargas
        </v-btn>

        <v-btn
            v-else
            class="mr-1 white--text d-print-none"
            color="black"
            :to="`/stores`"
        >
            <v-icon left>mdi-store</v-icon>Regresar
        </v-btn>

      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text>

        <v-simple-table v-if="!error && x_transaction_id">
            <template v-slot:default>
            <thead>
            
            </thead>
            <tbody>
                <tr>
                    <td class="font-weight-bold">Referencia</td>
                    <td>{{x_id_invoice}}</td>
                </tr>
                <tr>
                    <td class="font-weight-bold">Fecha</td>
                    <td>{{x_transaction_date}}</td>
                </tr>
                <tr>
                    <td class="font-weight-bold">Respuesta</td>
                    <td>
                        <v-chip
                            class=""
                            :color="status == 1 ? 'success' : status == 3 ? 'warning' : 'error'"
                            >
                            {{x_response}}
                        </v-chip>
                    </td>
                </tr>
                <tr>
                    <td class="font-weight-bold">Motivo</td>
                    <td>{{x_response_reason_text}}</td>
                </tr>
                <tr>
                    <td class="font-weight-bold">Banco</td>
                    <td>{{x_bank_name}}</td>
                </tr>
                <tr>
                    <td class="font-weight-bold">Recibo</td>
                    <td>{{x_transaction_id}}</td>
                </tr>
                <tr>
                    <td class="font-weight-bold">Total</td>
                    <td>{{x_amount | currency}}</td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>

        <h2 v-else>{{error}}</h2>

      </v-card-text>
    </v-card>
  </v-card>

  </v-container>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            x_id_invoice: '',
            x_transaction_date: '',
            x_response: '',
            x_response_reason_text: '',
            x_bank_name: '',
            x_transaction_id: '',
            x_currency_code: '',
            x_amount: '',
            status: '',
            error: '',
            store_id: '',

            apiUrl: process.env.VUE_APP_URL_API_PAYCO,
            loading: true
        }
    },

    mounted() {
        this.$store.state.menuAdminActive = true
        this.ref_payco = this.$route.query.ref_payco
        this.getTransactionPayco()
        
    },

    methods: {
        getTransactionPayco(){
            this.loading = true
            const config = {
				//headers: {'Authorization': "Bearer " + window.localStorage.client_diens.uid}
            }

            if (!this.ref_payco) {
                this.$swal({
                    title: "Facturas",
                    text: "Algo salío mal consultado la el comprobante.",
                    icon: "warning",
                    buttons: ['Salir', 'Entiendo'],
                    dangerMode: true,
                    })
                    .then((ok) => {
                    if (ok) {
                        this.$router.push('/stores')
                    }else{
                        this.$router.push('/stores')
                    }
                });
                return;
            }
            
			axios
				.get(`${this.apiUrl}/${this.ref_payco}`, config)
				.then(response => {
                    //eslint-disable-next-line
                    //console.log(response.data);
                    if (response.data.success) {
                        this.loading = false
                        return response
                    }
                    this.error = response.data.message ? response.data.message : `Error en la Transacción. ${response.data.data.x_type_payment}`
                    this.loading = false
				})
				.then(res => {
                    if (res) {
                        this.x_id_invoice = res.data.data.x_id_invoice
                        this.x_transaction_date = res.data.data.x_transaction_date
                        this.x_response = res.data.data.x_response
                        this.x_response_reason_text = res.data.data.x_response_reason_text
                        this.x_bank_name = res.data.data.x_bank_name
                        this.x_transaction_id = res.data.data.x_transaction_id
                        this.x_currency_code = res.data.data.x_currency_code
                        this.x_amount = res.data.data.x_amount
                        this.status = res.data.data.x_cod_transaction_state
                        this.store_id = res.data.data.x_extra1
                    }
                    
                    this.loading = false
                })
                
                
        }
        
    },
}
</script>