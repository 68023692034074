<template>
   <v-container fluid>
   <v-card flat>
        <v-toolbar
        color="grey darken-4"
        dark
        extended
        flat
        >
        <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
        </v-toolbar>

        <v-card
        class="mx-auto"
        max-width="1200"
        style="margin-top: -64px;"
        :loading="loading"
        v-if="companie"
        >
        <v-toolbar flat>
            <v-toolbar-title class="black--text">Mi Comercio</v-toolbar-title>

            <v-spacer></v-spacer>
            
            <!--<v-btn v-if="companie" :to="`/me/${companie.attributes.slug ? companie.attributes.slug : companie.id}`" color="black" class="white--text">
            <v-icon class="mr-1">mdi-open-in-new</v-icon>
            Ver Perfil público
            </v-btn>-->

            <!--<v-btn icon>
            <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>-->
            <h2 v-if="companie.attributes.credits">Créditos: ${{ companie.attributes.credits.toFixed(2) }}</h2>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text>

            <v-card
                class="mx-auto mb-5"
                max-width="100%"
                outlined
            >
                <v-list-item three-line>
                <v-list-item-content>
                    <v-list-item-title class="headline mb-3"> Resumen </v-list-item-title>
                    <v-list-item-subtitle style="display: block;" class="pb-2"> <v-icon>mdi-message-processing</v-icon> ENVIANDOS: <strong class="success--text">{{totalMessagesDelivered}}</strong> </v-list-item-subtitle>
                    <v-list-item-subtitle style="display: block;" class=""> <v-icon>mdi-message-alert</v-icon> NO ENVIANDOS: <strong class="error--text">{{totalMessagesCreated}}</strong> </v-list-item-subtitle>
                    
                </v-list-item-content>
                </v-list-item>

                <v-card-actions>
                
                </v-card-actions>
            </v-card>

            <v-data-table
                loading-text="Cargando... Por favor espere"
                :headers="headers"
                :items="messages"
                :search="search"
                :header-props="{ sortIcon: null }"
                class="elevation-1"
            >
                <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>ENVÍOS</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <download-excel
                      class = "mb-2 d-none d-sm-flex v-btn v-btn--contained theme--dark v-size--small black mr-1"
                      :data = "messages"
                      :fields = "headers_export"
                      worksheet = "FALCON"
                      name = "transaction_falcon.xls">
                          <v-icon left style="color:white;">mdi-microsoft-excel</v-icon> Descargar en Excel  
                    </download-excel>

                </v-toolbar>
                </template>

                <template v-slot:item.attributes.createdAt="{ item }">
                    {{$moment(item.attributes.createdAt).format('lll')}}
                </template>

                <template v-slot:item.attributes.status="{ item }">
                    <v-chip
                        :color="item.attributes.status == 'delivered' ? 'success' : item.attributes.status == 'created' ? 'warning' : 'error'"
                        class="ml-0"
                        label
                        small
                    >
                        {{ item.attributes.status == 'delivered' ? 'ENVIADO' : item.attributes.status == 'created' ? 'CREADO' : item.attributes.status.toUpperCase() }}
                    </v-chip>
                </template>

                 <template v-slot:item.attributes.type="{ item }">
                   <v-icon v-if="item.attributes.kind == 'call'">mdi-phone</v-icon>
                   <v-icon v-else>mdi-message-processing</v-icon>
                </template>

                <template v-slot:no-data>
                <v-btn color="primary" @click="getTransactions()">RECARGAR</v-btn>
                </template>
            </v-data-table>
           
        </v-card-text>
        </v-card>
    </v-card>

   </v-container>
</template>

<script>
import Parse from 'parse'
import JsonExcel from 'vue-json-excel'

export default {

    components:{
      'download-excel': JsonExcel,
    },

    data() {
        return {
            companie: '',
            messages: [],
            transaccion: '',
            loading: false,
            headers: [
              //{ text: 'Comercio', align: 'left', value: 'attributes.store' },
              //{ text: 'ID', align: 'left', value: 'id' },
              { text: 'ID', align: 'left', value: 'attributes.messageId' },
              { text: 'Destinatario', align: 'center', value: 'attributes.destination' },
              { text: 'Tipo', align: 'center', value: 'attributes.type' },
              { text: 'Estado', align: 'left', value: 'attributes.status' },
              { text: 'Fecha', align: 'left', value: 'attributes.createdAt' },
              //{ text: 'Acciones', align: 'center', value: 'action', sortable: false },
            ],
            search: '',
            modalTrandaction: false,

            labelText: '',
            headers_export: {
              'ID': 'attributes.messageId',
              'Destinatario': 'attributes.destination',
              'Tipo': 'attributes.type',
              'Estado': 'attributes.status',  
              'Fecha creación': {
                  field: 'attributes.createdAt',
                  callback: (value) => {
                      let startDate = value.toLocaleString()
                      return `${startDate}`;
                  }
              }
            },

            totalMessagesDelivered: 0,
            totalMessagesCreated: 0
        }
    },

    mounted() {
        this.$store.state.menuAdminActive = true
        this.getTransactions()
    },

    computed: {
        
    },

    methods: {

        async getTransactions(){
            try {
                let params = {
                    id: this.$route.params.transaction_id
                }

                let messages = await Parse.Cloud.run("getMessageByTransaction", params)
                this.messages = messages
                if (messages.length > 0) {
                    this.companie = messages[0].attributes.company

                    this.totalMessagesDelivered = messages.filter(message => {
                        if (message.attributes.status === 'delivered') {
                            return message
                        }
                    }).length

                    this.totalMessagesCreated = messages.filter(message => {
                        if (message.attributes.status === 'created') {
                            return message
                        }
                    }).length

                }
                //console.log(messages);
                this.loading = false
                this.dialogFilterTransaction = false

            } catch (error) {
                this.$snotify.error(error.message , 'Messages', {
                    timeout: 4000,
                    preventDuplicates: true
                 }); 
            }
        }
    },
}
</script>