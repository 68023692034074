<template>
   <v-container fluid>
   <v-card flat>
        <v-toolbar
        color="grey darken-4"
        dark
        extended
        flat
        >
        <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
        </v-toolbar>

        <v-card
        class="mx-auto"
        max-width="1100"
        style="margin-top: -64px;"
        :loading="loading"
        v-if="companie"
        >
        <v-toolbar flat>
            <v-toolbar-title class="black--text">Mi Comercio</v-toolbar-title>

            <v-spacer></v-spacer>
            
            <!--<v-btn v-if="companie" :to="`/me/${companie.attributes.slug ? companie.attributes.slug : companie.id}`" color="black" class="white--text">
            <v-icon class="mr-1">mdi-open-in-new</v-icon>
            Ver Perfil público
            </v-btn>-->

            <!--<v-btn icon>
            <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>-->
            <h2 v-if="companie.attributes.credits">Créditos: ${{ companie.attributes.credits.toFixed(2) }}</h2>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text>
            <v-data-table
                loading-text="Cargando... Por favor espere"
                :headers="headers"
                :items="payments"
                :search="search"
                :header-props="{ sortIcon: null }"
                class="elevation-1"
            >
                <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Recargas</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="black" dark class="mb-2 mr-1 d-none d-sm-flex" @click="modalRecharge = true" small> <v-icon left>mdi-cash-usd-outline</v-icon> Recargar cuenta </v-btn>
                </v-toolbar>
                </template>

               <template v-slot:item.attributes.createdAt="{ item }">
                    {{$moment(item.attributes.createdAt).format('lll')}}
                </template>

                <template v-slot:item.attributes.updatedAt="{ item }">
                    {{$moment(item.attributes.updatedAt).format('lll')}}
                </template>

                <template v-slot:item.attributes.amount="{ item }">
                    {{ item.attributes.amount | currency }}
                </template>

                <template v-slot:item.attributes.status="{ item }">
                    <v-chip
                        :color="item.attributes.status == '1' ? 'success' : item.attributes.status == '3' ? 'warning' : 'error'"
                        class="ml-0"
                        label
                        small
                    >
                        {{ item.attributes.status == '1' ? 'ACEPTADA' : item.attributes.status == '3' ? 'PENDIENTE' : item.attributes.response.toUpperCase() }}
                    </v-chip>
                </template>

                <template v-slot:no-data>
                <v-btn color="primary" @click="init()">RECARGAR</v-btn>
                </template>
            </v-data-table>
        </v-card-text>
        </v-card>
    </v-card>

    <!-- Dialogo Dialogo recarga -->
    <v-dialog
      v-model="modalRecharge"
      width="700"
      scrollable
    >
      <v-card>
        <v-card-title
          class="title grey lighten-2"
          primary-title
        >
          <span class="text-capitalize"> Recargar <strong v-if="amount && amount == 50">(Pack 50 USD)</strong> <strong v-else-if="amount && amount == 100">(Pack 100 USD)</strong> <strong v-else-if="amount && amount == 150">(Pack 150 USD)</strong>  <strong v-else-if="amount && amount == 500">(Pack 500 USD)</strong> </span>
        </v-card-title>

        <v-card-text>
          <div class="mb-2"></div>
          <v-row align="center" justify="center">

             <v-col
              cols="12"
              class="body-2"
            >
              Selecciona uno de nuestros paquetes a continuación:
             </v-col>

            <v-col
              cols="12"
              sm="6"
              class="ma-0 pa-0 pb-2 pr-1"
            >
              <v-card
                class="ma-0 pa-0"
                max-width="400"
                outlined
                color="info"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-1 white--text">
                      Paquete $50 USD
                    </v-list-item-title>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Valor SMS: $0.0049</v-list-item-subtitle>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Valor Llamada: $0.033</v-list-item-subtitle>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Total: 10,204 SMS</v-list-item-subtitle>
                  </v-list-item-content>

                
                </v-list-item>

                <v-card-actions class="pt-0 mt-0">
                  <v-btn
                    outlined
                    rounded
                    text
                    small
                    class="white--text"
                    @click="amount = 50"
                  >
                    Seleccionar Paquete
                  </v-btn>
                  <v-icon v-if="amount == 50" class="white--text">mdi-check</v-icon>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              class="ma-0 pa-0 pb-2 pr-1"
            >
              <v-card
                class="ma-0 pa-0"
                max-width="400"
                outlined
                color="orange"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-1 white--text">
                      Paquete $100 USD
                    </v-list-item-title>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Valor SMS: $0.0039</v-list-item-subtitle>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Valor Llamada: $0.033</v-list-item-subtitle>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Total: 25,641 SMS</v-list-item-subtitle>
                  </v-list-item-content>

                
                </v-list-item>

                <v-card-actions class="pt-0 mt-0">
                  <v-btn
                    outlined
                    rounded
                    text
                    small
                    class="white--text"
                    @click="amount = 100"
                  >
                    Seleccionar Paquete
                  </v-btn>
                  <v-icon v-if="amount == 100" class="white--text">mdi-check</v-icon>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              class="ma-0 pa-0 pb-2 pr-1"
            >
              <v-card
                class="ma-0 pa-0"
                max-width="400"
                outlined
                color="success"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-1 white--text">
                      Paquete $150 USD
                    </v-list-item-title>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Valor SMS: $0.0039</v-list-item-subtitle>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Valor Llamada: $0.030</v-list-item-subtitle>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Total: 38,461 SMS</v-list-item-subtitle>
                  </v-list-item-content>

                
                </v-list-item>

                <v-card-actions class="pt-0 mt-0">
                  <v-btn
                    outlined
                    rounded
                    text
                    small
                    class="white--text"
                    @click="amount = 150"
                  >
                    Seleccionar Paquete
                  </v-btn>
                  <v-icon v-if="amount == 150" class="white--text">mdi-check</v-icon>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              class="ma-0 pa-0 pb-2"
            >
              <v-card
                class="ma-0 pa-0"
                max-width="400"
                outlined
                color="purple"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="headline mb-1 white--text">
                      Paquete $500 USD
                    </v-list-item-title>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Valor SMS: $0.0.0035</v-list-item-subtitle>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Valor Llamada: $0.030</v-list-item-subtitle>
                    <v-list-item-subtitle class="white--text"><v-icon small class="white--text">mdi-check-circle-outline</v-icon> Total: 144,928 SMS</v-list-item-subtitle>
                  </v-list-item-content>

                
                </v-list-item>

                <v-card-actions class="pt-0 mt-0">
                  <v-btn
                    outlined
                    rounded
                    text
                    small
                    class="white--text"
                    @click="amount = 500"
                  >
                    Seleccionar Paquete
                  </v-btn>
                  <v-icon v-if="amount == 500" class="white--text">mdi-check</v-icon>
                </v-card-actions>
              </v-card>
            </v-col>
           
          </v-row>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="modalRecharge = false"
          >
           <v-icon left>mdi-close</v-icon> Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            outlined
            @click="rechargePay()"
          >
           <v-icon left>mdi-send</v-icon> Pagar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *End* Dialogo recarga -->

   </v-container>
</template>

<script>
import Parse from 'parse'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

const isPhone = (value) => /^3[0-3]\d{8}$/.test(value); 

export default {

    components:{
        
    },

    mixins: [validationMixin],

    validations: {
      phone: {
        required,
        phoneValid: isPhone
      },
    },

    data() {
        return {
            companie: '',
            credential: '',
            payments: [],
            transaccion: '',
            loading: false,
            loadingButton: false,
            headers: [
              //{ text: 'Comercio', align: 'left', value: 'attributes.store' },
              //{ text: 'ID', align: 'left', value: 'id' },
              { text: 'Transacción', align: 'left', value: 'attributes.transactionId' },
              { text: 'Valor', align: 'left', value: 'attributes.amount' },
              { text: 'Método', align: 'left', value: 'attributes.method' },
              { text: 'Fecha creación', align: 'left', value: 'attributes.createdAt' },
              { text: 'Fecha modificación', align: 'left', value: 'attributes.updatedAt' },
              { text: 'Respuesta', align: 'left', value: 'attributes.response' },
              { text: 'Estado', align: 'left', value: 'attributes.status' },
            ],
            search: '',
            seeApiKey: false,
            seeSecretKey: false,
            validCallSMS: true,
            radioGroup: '1',
            phone: '',
            body: '',

            phoneRules: [
                v => !!v || 'Título es requerido'
            ],
            bodyRules: [
                v => !!v || 'Mensaje es requerido',
                v => !!v && v.length <= 160 || 'El mensaje no debe sobrepasar los 160 caracteres'
            ],
            payload: '',
            modalTrandaction: false,
            amount: '',
            modalRecharge: false,
        }
    },

    mounted() {
        this.$store.state.menuAdminActive = true
        this.init()
    },

    computed: {
        phoneErrors () {
            const errors = []
            if (!this.$v.phone.$dirty) return errors
            !this.$v.phone.phoneValid && errors.push('El celular no es valido.')
            !this.$v.phone.required && errors.push('Celular es requerido.')
            return errors
        },
    },

    methods: {

        async init(){
            this.loading = true
            try {
                let params = {
                    id: Parse.User.current().id
                }

                let companie = await Parse.Cloud.run("getCompany", params)
                this.companie = companie
                //console.log(companie.id);
                this.getPayments()
                //this.getCallPricing()

            } catch (error) {
                this.$snotify.error(error.message , 'Empresa', {
                    timeout: 4000,
                    preventDuplicates: true
                 }); 
            }
        },
        
        async getPayments(){
            this.loading = true
            try {
                let payments = await Parse.Cloud.run("getPayments")
                this.payments = payments
                //console.log(companie.id);
                this.loading = false

            } catch (error) {
                this.$snotify.error(error.message , 'Empresa', {
                    timeout: 4000,
                    preventDuplicates: true
                 }); 
            }
        },

        async getCallPricing(){
            try {
                let pricings = await Parse.Cloud.run("registerCountriesInPricing")
                console.log(pricings);
               
            } catch (error) {
                this.$snotify.error(error.message , 'Precios', {
                    timeout: 4000,
                    preventDuplicates: true
                 }); 
            }
        },

        rechargePay(){
            this.loadingButton = true
            if (!this.amount || this.amount < 50) {
                this.$snotify.error(`Monto de recarga muy bajo` , 'Recargas', {
                    timeout: 4000,
                    preventDuplicates: true
                });
                this.loadingButton = false
                return;
            }

            //eslint-disable-next-line
            var handler = ePayco.checkout.configure({
                key: '36dcadb137adf6eea8c56e63cd254b27',
                test: false
            })

            var data = {
                //Parametros compra (obligatorio)
                name: `Recarga Falcon por ${this.amount}`,
                description: `Recarga falcon del cliente ${this.companie.id} - ${this.companie.attributes.name} por valor de ${this.amount} de la ciudad de ${this.companie.attributes.city}`,
                currency: "usd",
                amount: this.amount,
                tax_base: "0",
                tax: "0",
                country: "co",
                lang: "es",

                //Onpage="false" - Standard="true"
                external: "false",

                //Atributos opcionales
                extra1: this.companie.id,
                extra2: this.companie.attributes.name,
                extra3: this.companie.attributes.owner.id,
                confirmation: process.env.VUE_APP_URL_CONFIRMATION_PAYCO,
                response: process.env.VUE_APP_URL_RESPONSE_PAYCO,

                //Atributos cliente
                /*name_billing: "Andres Perez",
                address_billing: "Carrera 19 numero 14 91",
                type_doc_billing: "cc",
                mobilephone_billing: "3050000000",
                number_doc_billing: "100000000",*/

                //atributo deshabilitación metodo de pago
                //methodsDisable: ["TDC", "PSE","SP","CASH","DP"]
            }

            //eslint-disable-next-line
            //console.log(data);

            handler.open(data)

            setTimeout(() => {
                this.loadingButton = false
            }, 5000)
           
        },

        validPhonePayload(payload){
            this.payload = payload
        },

        showDialogTransaction(item){
            this.transaccion = item
            this.modalTrandaction = true
        }

    },
}
</script>